import { useState, useEffect } from 'react';
import { parseISO, format, subHours, addHours } from 'date-fns';
import { usePromo } from './usePromo';
import { useMarkdown } from './useMarkdown';
import { useHoliday } from './useHoliday';
import { UnifiedData } from '../types/unifiedData';
import { useCombined } from './useCombined';
import { CombinedData } from '../types/combined';


// Helper function to process the unified data
const processUnifiedData = (
  combinedData: CombinedData[],
  promoData: any[],
  markdownData: any[],
  holidayData: any[]
): UnifiedData[] => {
  try {
    if (!combinedData) {
      return [];
    }
    // Process and transform the final data
    const unifiedData = combinedData.map((row) => {
      const orderDate = parseISO(row.order_day);
      const dateStart = format(subHours(orderDate, 12), 'yyyy-MM-dd');
      const dateEnd = format(addHours(orderDate, 12), 'yyyy-MM-dd');

      const hasPromo = promoData?.some(p => 
        p.asin === row.asin && 
        p.site_id === row.site_id && 
        format(new Date(p.order_day), 'yyyy-MM-dd') === format(orderDate, 'yyyy-MM-dd')
      );

      // TODO: Re-introduce markdown after optimizing query
      // const hasMarkdown = markdownData?.some(m => {
      //   const mStartDate = new Date(m.start_date);
      //   const mEndDate = new Date(m.end_date);
      //   const rowDate = new Date(row.order_day);
      //   return m.asin === row.asin && 
      //          m.site_id === row.site_id && 
      //          rowDate >= mStartDate && 
      //          rowDate <= mEndDate;
      // });

      const isHoliday = holidayData?.some(h => 
        format(new Date(h.holiday_date), 'yyyy-MM-dd') === format(orderDate, 'yyyy-MM-dd')
      );

      let promoOrMarkdown = "None";
      if (hasPromo) promoOrMarkdown = "Promo";
      // if (hasMarkdown) promoOrMarkdown = "Markdown";

      return {
        ...row,
        is_on_promo: hasPromo ? 1 : 0,
        // is_on_markdown: hasMarkdown ? 1 : 0,
        holiday_date: isHoliday ? format(orderDate, 'yyyy-MM-dd') : undefined,
        is_holiday: isHoliday ? 1 : 0,
        promo_or_markdown: promoOrMarkdown,
        date_start: dateStart,
        date_end: dateEnd
      } as UnifiedData;
    });

    return unifiedData;
  } catch (error) {
    throw error;
  }
};

export const useUnifiedData = (
  warehouse: string, 
  asin: string, 
  business_id: string, 
  start_date: string, 
  end_date: string, 
  fcd: string, 
  models_versions: string
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [allData, setAllData] = useState<UnifiedData[] | null>(null);

  // Parameters for API calls
  const params = {
    combinedParams: { asin, warehouse, start_date, end_date, fcd, models_versions, business_id },
    promoParams: { asin, warehouse, start_date, end_date },
    markdownParams: { asin, warehouse, start_date, end_date },
    holidayParams: { business_id, warehouse, fcd }
  };

  // Initialize all hooks at the top level
  const combined = useCombined(params.combinedParams, true);
  const promo = usePromo(params.promoParams, true);
  // const markdown = useMarkdown(params.markdownParams, true);
  const holiday = useHoliday(params.holidayParams, true);

  useEffect(() => {
    let isMounted = true;
    const fetchDataSequentially = async () => {
      if (!isMounted) return;
      setIsLoading(true);
      setError(null);

      try {
        let processedData: UnifiedData[] = [];

        if(combined.data !== null &&
          promo.data !== null &&
          // markdown.data !== null &&
          holiday.data !== null) {
            processedData = processUnifiedData(
              combined.data ?? [],
              promo.data ?? [],
              [],
              holiday.data ?? []
            );
        }
        
        if (isMounted) {
          setAllData(processedData);
          setIsLoading(false);

        }
      } catch (error) {
        console.error('Error in sequential fetch:', error);
        if (isMounted) {
          setError(error instanceof Error ? error.message : 'An error occurred');
          setIsLoading(false);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);

        }
      }
    };
    fetchDataSequentially();
    return () => {
      isMounted = false;
    };
  }, [
    combined.data,
    promo.data,
    // markdown.data,
    holiday.data,
    combined.isLoading,
    promo.isLoading,
    // markdown.isLoading,
    holiday.isLoading,
    combined.error,
    promo.error,
    // markdown.error,
    holiday.error
  ]);
  return {
    data: allData,
    isLoading: isLoading || 
              combined.isLoading || 
              promo.isLoading || 
              // markdown.isLoading || 
              holiday.isLoading,
    error: error || 
           combined.error || 
           promo.error || 
          //  markdown.error || 
           holiday.error
  };
};
