import { useState, useEffect } from 'react';
import { HolidayData, HolidayParams } from '../types/holiday';
import { HolidayService } from '../services/holidayService';

export const useHoliday = (params: HolidayParams, shouldFetch: boolean = false) => {
  const [data, setData] = useState<HolidayData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let dataAchieved: boolean;

    const fetchWithRetry = async (retries = 3, delay = 1000) => {
      let lastError: Error | null = null;

      for (let attempt = 1; attempt <= retries; attempt++) {
        try {
          const response = await HolidayService.getHoliday(params);
          if (response !== null && response !== undefined) {
            console.log('Holiday data:', response);
            setData(response);
            setError(null);
            dataAchieved = true;
            break;
          }
          lastError = new Error('No holiday data received');
          
        } catch (err) {
          lastError = err as Error;
        }

        if (attempt < retries) {
          const backoffDelay = delay * Math.pow(2, attempt - 1);
          console.log(`Attempt ${attempt}/${retries} failed. Retrying in ${backoffDelay}ms...`);
          await new Promise(resolve => setTimeout(resolve, backoffDelay));
        }
      }
      if(!dataAchieved) {
        console.error(`All ${retries} attempts failed:`, lastError);
        setError('Failed to fetch holiday data after multiple attempts');
      }
    };

    const fetchData = async () => {
      if (!shouldFetch) return;

      setIsLoading(true);
      setError(null);
      setData([]);
      
      try {
        await fetchWithRetry();
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    params.business_id, 
    params.fcd, 
    params.warehouse,
    shouldFetch
  ]);

  return { data, isLoading, error };
};
