import React, { useMemo, useEffect, useState } from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import Header from '@amzn/awsui-components-react/polaris/header';
import Button from '@amzn/awsui-components-react/polaris/button';

interface UnifiedData {
  order_day?: string;
  site_id?: string;
  business_id?: string;
  asin?: string;
  units?: number;
  instock_raw?: number;
  p50?: number;
  p90?: number;
  model_version?: string;
  f3_segment?: string;
  f3_category_group?: string;
  f3_vertical?: string;
  promo_or_markdown?: string;
  date_start?: string;
  date_end?: string;
}

interface UnifiedTableProps {
  data: UnifiedData[];
  isLoading?: boolean;
}

export const UnifiedTable: React.FC<UnifiedTableProps> = ({ data, isLoading = false }) => {
  const [sortingState, setSortingState] = useState<TableProps.SortingState<UnifiedData>>({
    sortingColumn: {
      sortingField: 'order_day'
    },
    isDescending: false
  });

  // Helper function to safely convert values to string
  const safeToString = (value: any): string => {
    if (value === null || value === undefined) return '-';
    return String(value);
  };

  // Helper function to safely parse date
  const safeDate = (dateString: string | undefined): Date | null => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date;
  };

  const columnDefinitions = [
    {
      id: 'order_day',
      header: 'Order Day',
      cell: (item: UnifiedData) => safeToString(item?.order_day),
      sortingField: 'order_day',
      sortable: true
    },
    {
      id: 'site_id',
      header: 'Site ID',
      cell: (item: UnifiedData) => safeToString(item?.site_id),
      sortingField: 'site_id',
      sortable: true
    },
    {
      id: 'business_id',
      header: 'Business ID',
      cell: (item: UnifiedData) => safeToString(item?.business_id),
      sortingField: 'business_id',
      sortable: true
    },
    {
      id: 'asin',
      header: 'ASIN',
      cell: (item: UnifiedData) => safeToString(item?.asin),
      sortingField: 'asin',
      sortable: true
    },
    {
      id: 'units',
      header: 'Units',
      cell: (item: UnifiedData) => safeToString(item?.units),
      sortingField: 'units',
      sortable: true
    },
    {
      id: 'instock_raw',
      header: 'Instock Raw',
      cell: (item: UnifiedData) => safeToString(item?.instock_raw),
      sortingField: 'instock_raw',
      sortable: true
    },
    {
      id: 'p50',
      header: 'P50',
      cell: (item: UnifiedData) => {
        const value = item?.p50;
        return typeof value === 'number' ? value.toFixed(2) : '-';
      },
      sortingField: 'p50',
      sortable: true
    },
    {
      id: 'p90',
      header: 'P90',
      cell: (item: UnifiedData) => {
        const value = item?.p90;
        return typeof value === 'number' ? value.toFixed(2) : '-';
      },
      sortingField: 'p90',
      sortable: true
    },
    {
      id: 'model_version',
      header: 'Model Version',
      cell: (item: UnifiedData) => safeToString(item?.model_version),
      sortingField: 'model_version',
      sortable: true
    },
    {
      id: 'f3_segment',
      header: 'F3 Segment',
      cell: (item: UnifiedData) => safeToString(item?.f3_segment),
      sortingField: 'f3_segment',
      sortable: true
    },
    {
      id: 'f3_category_group',
      header: 'F3 Category Group',
      cell: (item: UnifiedData) => safeToString(item?.f3_category_group),
      sortingField: 'f3_category_group',
      sortable: true
    },
    {
      id: 'f3_vertical',
      header: 'F3 Vertical',
      cell: (item: UnifiedData) => safeToString(item?.f3_vertical),
      sortingField: 'f3_vertical',
      sortable: true
    },
    {
      id: 'promo_or_markdown',
      header: 'Promo',
      cell: (item: UnifiedData) => safeToString(item?.promo_or_markdown),
      sortingField: 'promo_or_markdown',
      sortable: true
    },
    {
      id: 'date_start',
      header: 'Start Date',
      cell: (item: UnifiedData) => safeToString(item?.date_start),
      sortingField: 'date_start',
      sortable: true
    },
    {
      id: 'date_end',
      header: 'End Date',
      cell: (item: UnifiedData) => safeToString(item?.date_end),
      sortingField: 'date_end',
      sortable: true
    }
  ];

  const sortedData = useMemo(() => {
    if(!data || !Array.isArray(data)) return [];
    const safeData = [...data].filter(item => item !== null && item !== undefined);
    
    return safeData.sort((a, b) => {
      const field = sortingState.sortingColumn?.sortingField as keyof UnifiedData;
      
      if (field === 'order_day') {
        const dateA = safeDate(a?.order_day);
        const dateB = safeDate(b?.order_day);
        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;
        return sortingState.isDescending 
          ? dateB.getTime() - dateA.getTime()
          : dateA.getTime() - dateB.getTime();
      }
  
      let aValue = a[field];
      let bValue = b[field];
  
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortingState.isDescending ? bValue - aValue : aValue - bValue;
      }
  
      aValue = safeToString(aValue);
      bValue = safeToString(bValue);
      
      return sortingState.isDescending 
        ? bValue.localeCompare(aValue)
        : aValue.localeCompare(bValue);
    });
  }, [data, sortingState]);
  useEffect(() => {
    console.log('UnifiedTable render:', {
      isLoading,
      dataLength: data?.length,
      sortedDataLength: sortedData?.length,
      invalidRows: data?.length ? data.length - sortedData.length : 0
    });
  }, [isLoading, data, sortedData]);

  const handleExportCSV = () => {
    try {
      if (!sortedData.length) return;
      const headers = columnDefinitions.map(col => col.header).join(',');
      const rows = sortedData.map(item => 
        columnDefinitions
          .map(col => `"${(col.cell(item) || '').toString().replace(/"/g, '""')}"`)
          .join(',')
      );
      const csv = [headers, ...rows].join('\n');
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `unified-data-export-${new Date().toISOString()}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
    }
  };

  return (
    <Box margin="l">
      <Header
        variant="h1"
        actions={
          <Button
            onClick={handleExportCSV}
            disabled={!sortedData.length}
          >
            Export to CSV
          </Button>
        }
      >
        Unified Data Table {isLoading ? '(Loading...)' : `(${sortedData.length} rows)`}
      </Header>
      <Table
        columnDefinitions={columnDefinitions}
        items={sortedData}
        loading={isLoading}
        variant="full-page"
        stickyHeader
        resizableColumns
        wrapLines
        stripedRows
        sortingColumn={sortingState.sortingColumn}
        sortingDescending={sortingState.isDescending} 
        onSortingChange={({ detail }) => {
          console.log('Sorting changed:', detail);
          setSortingState({
            sortingColumn: detail.sortingColumn,
            isDescending: detail.sortingColumn.sortingField === sortingState.sortingColumn.sortingField 
              ? !sortingState.isDescending 
              : false
          });
        }}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No data</b>
            <Box padding={{ bottom: "s" }}>
              {isLoading ? "Loading data..." : "No data available"}
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default UnifiedTable;
