import axios, { AxiosError } from 'axios';
import { PromoData, PromoParams } from '../types/promo';

const API_BASE_URL = 'https://2qtht5pfpj.execute-api.us-east-1.amazonaws.com/jwt';

export class PromoService {
  private static readonly timeout = 60000; // 20 seconds timeout

  static async getPromos(params: PromoParams): Promise<PromoData[]> {
    try {
      const response = await axios.get<PromoData[]>(`${API_BASE_URL}/promo`, {
        params: {
          asin: params.asin,
          warehouse: params.warehouse,
          start_date: params.start_date,
          end_date: params.end_date
        },
        timeout: this.timeout
      });

      return response.data;
      // return actualsDataMock;
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        console.error('Status:', axiosError.response.status);
        console.error('Data:', axiosError.response.data);
      }
      throw error;
    }
  }
}
