import React, { useEffect, useState } from 'react';
import Box from "@amzn/meridian/box";
import Row from "@amzn/meridian/row";
import UserInput from '../inputFields/UserInput';
import WorkflowDate from '../inputFields/SearchDates';
import Select, { SelectOption } from "@amzn/meridian/select";
import './InputForm.scss';


interface InputFormProps {
  initialValues?: {
    warehouse: string;
    asin: string;
    business_id: string;
    start_date: string;
    end_date: string;
    fcd: string;
    models_versions: string;
  };
  onParamsChange: (params: {
    warehouse: string;
    asin: string;
    business_id: string;
    start_date: string;
    end_date: string;
    fcd: string;
    models_versions: string;
  }) => void;
}

interface FormErrors {
  warehouse?: string;
  asin?: string;
  start_date?: string;
  end_date?: string;
  fcd?: string;
}

// list of Business options
const businessList = [
  { label: "UFF", value: "UFF" },
  { label: "AFS", value: "AFS" },
];


// list of Model options
const modelList = [
  { label: "Bosco", value: "numpyro-promo-lifts-v1" },
  { label: "Ensemble", value: "ensemble-bosco2lgbm-promo-seasonality-400-v1" },
];
const InputForm: React.FC<InputFormProps> = ({ onParamsChange, initialValues }) => {
  const [selectedBusiness, setSelectedBusiness] = useState<string>(initialValues?.business_id || 'AFS');
  const [selectedModel, setSelectedModel] = useState<string>(initialValues?.models_versions || 'numpyro-promo-lifts-v1');

  const getCurrentDateString = () => {
    const date = new Date();
    return date.toISOString().split('T')[0];
  };
  
  const getFutureDateString = (daysToAdd: number) => {
    const date = new Date();
    date.setDate(date.getDate() + daysToAdd);
    return date.toISOString().split('T')[0];
  };
  
  const [formState, setFormState] = useState({
    warehouse: initialValues?.warehouse || 'MAB2',
    asin: initialValues?.asin || 'B09LNW7L55',
    business_id: initialValues?.business_id || 'AFS',
    start_date: initialValues?.start_date || getCurrentDateString(),
    end_date: initialValues?.end_date || getFutureDateString(30),
    fcd: initialValues?.fcd || getFutureDateString(7),
    models_versions: initialValues?.models_versions || 'numpyro-promo-lifts-v1'
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const validateForm = (values: typeof formState): FormErrors => {
  const errors: FormErrors = {};

    // Create dates at the start of the day in local timezone
    const startDate = new Date(values.start_date + 'T00:00:00');
    const endDate = new Date(values.end_date + 'T00:00:00');
    const fcdDate = new Date(values.fcd + 'T00:00:00');
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Warehouse validation
    if (!values.warehouse) {
      errors.warehouse = 'Warehouse is required';
    } else if (!/^[A-Z0-9]{4}$/.test(values.warehouse)) {
      errors.warehouse = 'Warehouse must be 4 characters (letters and numbers)';
    }

    // ASIN validation
    if (!values.asin) {
      errors.asin = 'ASIN is required';
    } else if (!/^[A-Z0-9]{10}$/.test(values.asin)) {
      errors.asin = 'ASIN must be 10 characters';
    }

    if (!values.start_date) {
      errors.start_date = 'Start date is required';
    } else if (startDate > endDate) {
      errors.start_date = 'Start date must be before end date';
    }

    // End date validations
    if (!values.end_date) {
      errors.end_date = 'End date is required';
    } else {
      const maxDate = new Date(fcdDate);
      maxDate.setDate(maxDate.getDate() + 90);
      maxDate.setHours(0, 0, 0, 0);

      if (endDate > maxDate) {
        errors.end_date = 'End date cannot be more than 90 days after FCD';
      }
    }
    if (!values.fcd) {
      errors.fcd = 'FCD is required';
    } else if (fcdDate < startDate) {
      errors.fcd = 'FCD cannot be in the past';
    }

    return errors;
  };

  useEffect(() => {
    if (initialValues) {
      setFormState(initialValues);
      setSelectedBusiness(initialValues.business_id);
    }
  }, [initialValues]);

  const updateField = (field: keyof typeof formState) => (value: string) => {
    const newState = { ...formState, [field]: value };
    setFormState(newState);
    onParamsChange(newState);
    setFormState(newState);
    
    const fieldErrors = validateForm(newState);
    setErrors(fieldErrors);
    if (Object.keys(fieldErrors).length === 0) {
      onParamsChange(newState);
    }
  };

  const handleBusinessChange = (value: string | undefined) => {
    if (value) {
      setSelectedBusiness(value);
      const newState = { ...formState, business_id: value };
      setFormState(newState);
      onParamsChange(newState);
    }
  };
  const handleModelChange = (value: string | undefined) => {
    if (value) {
      setSelectedModel(value);
      const newState = { ...formState, model: value };
      setFormState(newState);
      onParamsChange(newState);
    }
  };

  return (
    <Row alignmentHorizontal="center">
      <Box spacingInset="xsmall" className="form-container">
        <Row spacingInset="small" className="form-row">
          <Box className="input-container">
            <UserInput
              value={formState.asin}
              setValue={updateField('asin')}
              label="ASIN"
              placeholder="Enter ASIN..."
              error={errors.asin}
            />
          </Box>
          <Box className="input-container">
            <UserInput
              value={formState.warehouse}
              setValue={updateField('warehouse')}
              label="Warehouse"
              placeholder="Enter warehouse..."
              error={errors.warehouse}
            />
          </Box>
          <Box className="input-container">
            <Select 
              placeholder="Business" 
              value={selectedBusiness}
              onChange={handleBusinessChange}
              width={300} 
              label="Business"
            >
              {businessList.map((option, i) => (
                <SelectOption 
                  value={option.value} 
                  key={i} 
                  label={option.label}
                />
              ))}
            </Select>
          </Box>
          <Box className="input-container">
            <Select 
              placeholder="Model" 
              value={selectedModel}
              onChange={handleModelChange}
              width={300} 
              label="Model"
            >
              {modelList.map((option, i) => (
                <SelectOption 
                  value={option.value} 
                  key={i} 
                  label={option.label}
                />
              ))}
            </Select>
          </Box>
        </Row>
        <Row spacingInset="small" className="form-row">
          <Box className="input-container">
            <WorkflowDate
              onChangeEvent={updateField('start_date')}
              setDate={formState.start_date}
              label="Start Date"
              error={errors.start_date}
            />
          </Box>
          <Box className="input-container">
            <WorkflowDate
              onChangeEvent={updateField('end_date')}
              setDate={formState.end_date}
              label="End Date"
              error={errors.end_date}
            />
          </Box>
          <Box className="input-container">
            <WorkflowDate
              onChangeEvent={updateField('fcd')}
              setDate={formState.fcd}
              label="FCD"
              error={errors.fcd}
            />
          </Box>
        </Row>
      </Box>
    </Row>
  );
};

export default InputForm;
