import axios, { AxiosError } from 'axios';
import { CombinedData, CombinedDataParams } from '../types/combined';

const API_BASE_URL = 'https://2qtht5pfpj.execute-api.us-east-1.amazonaws.com/jwt';

export class CombinedService {
  private static readonly timeout = 60000; // 20 seconds timeout

  static async getCombined(params: CombinedDataParams): Promise<CombinedData[]> {
    try {
      const response = await axios.get<CombinedData[]>(`${API_BASE_URL}/combined-data`, {
        params: {
          asin: params.asin,
          warehouse: params.warehouse,
          start_date: params.start_date,
          end_date: params.end_date,
          fcd: params.fcd,
          models_versions: params.models_versions,
          business_id: params.business_id
        },
        timeout: this.timeout
      });
      return response.data;
      // return actualsDataMock;
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        console.error('Status:', axiosError.response.status);
        console.error('Data:', axiosError.response.data);
      }
      throw error;
    }
  }
}
