import axios, { AxiosError } from 'axios';
import { HolidayData, HolidayParams } from '../types/holiday';

const API_BASE_URL = 'https://2qtht5pfpj.execute-api.us-east-1.amazonaws.com/jwt';

export class HolidayService {
  private static readonly timeout = 60000; // 20 seconds timeout

  static async getHoliday(params: HolidayParams): Promise<HolidayData[]> {
    try {
      const response = await axios.get<HolidayData[]>(`${API_BASE_URL}/holiday`, {
        params: {
          business_id: params.business_id,
          warehouse: params.warehouse,
          fcd: params.fcd,
        },
        timeout: this.timeout

      });

      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        console.error('Status:', axiosError.response.status);
        console.error('Data:', axiosError.response.data);
      }
      throw error;
    }
  }
}
