import React, { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import {
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryAxis,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryGroup,
  VictoryBar,
  VictoryLabel
} from 'victory';
import Box from '@amzn/awsui-components-react/polaris/box';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Legend from './Legend';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';

import html2canvas from 'html2canvas';

interface ForecastChartProps {
  data: UnifiedData[];
  asin: string;
  title: string;
}

import { LegendItem } from './Legend';
import { UnifiedData } from '../../types/unifiedData';

const legendItems: LegendItem[] = [
  { 
    name: "Final P50 Forecast", 
    color: "firebrick", 
    type: "line" 
  },
  { 
    name: "Final P90 Forecast", 
    color: "firebrick", 
    type: "line", 
    isDashed: true 
  },
  { 
    name: "FAST P50 Forecast", 
    color: "steelblue", 
    type: "line"
  },
  { 
    name: "FAST P90 Forecast", 
    color: "steelblue", 
    type: "line", 
    isDashed: true 
  },
  { 
    name: "Actual", 
    color: "hsl(120, 70%, 50%)", 
    type: "scatter" 
  },
  {
    name: "Promotion",
    color: "rgba(128, 128, 128, 0.15)",
    type: "rect"
  },
  {
    name: "Markdown",
    color: "rgba(128, 0, 128, 0.15)",
    type: "rect"
  },
  {
    name: "Holiday",
    color: "red",
    type: "line",
    isDashed: true,
    dashPattern: "4,4,1,4"
  }
];


const ForecastChart: React.FC<ForecastChartProps> = ({ data, asin, title }) => {
  const [isDataReady, setIsDataReady] = useState(false);
  const safeData = useMemo(() => {
    if (!Array.isArray(data) || !asin) return [];
    return data.filter(d => d && d.asin === asin) || [];
  }, [data, asin]);

  const sites = useMemo(() => {
    if (!safeData.length) return [];
    return Array.from(
      new Set(
        safeData
          .filter(d => d?.business_id && d?.site_id)
          .map(d => `${d.business_id} – ${d.site_id}`)
      )
    ).filter(site => site !== ' – ');
  }, [safeData]);

  useEffect(() => {
    setIsDataReady(safeData.length > 0 && sites.length > 0);
  }, [safeData, sites]);

  if (!isDataReady) {
    return (
      <Box>
        <Alert type="info" header="Loading data">
          Preparing chart data...
        </Alert>
      </Box>
    );
  }
  const handleExport = async() => {
    const chartElement = document.querySelector('.victory-chart') as HTMLDivElement;
    if (!chartElement) return;
    try {
      const canvas = await html2canvas(chartElement, {
        scale: 2,
        useCORS: true,
        logging: false,
        windowWidth: chartElement.scrollWidth,
        windowHeight: chartElement.scrollHeight,
        scrollX: window.scrollX,
        scrollY: window.scrollY
      });
      
      const link = document.createElement('a');
      link.download = `forecast-chart-${asin}-${format(new Date(), 'yyyy-MM-dd-HH-mm')}.png`;
      link.href = canvas.toDataURL('image/png');
      link.click();
    } catch (error) {
      console.error('Failed to generate chart image:', error);
    }
  }

  return (
    <Box padding="l">
     <Header
        variant="h1"
        actions={
          <Button
            onClick={handleExport}
          >
            Export to CSV
          </Button>
        }
      ></Header>

      <div style={{ width: '100%', minHeight: 500 }}>
        {sites.map((site) => {
          const siteData = safeData.filter(d => 
            d && `${d.business_id} – ${d.site_id}` === site
          );

          if (!siteData.length) return null;

          const sortedData = Array.from(
            new Map(
              siteData.map(item => [item.order_day, item])
            ).values()
          ).sort((a, b) => 
            new Date(a.order_day).getTime() - new Date(b.order_day).getTime()
          );

          const finalForecastData = sortedData
            .filter(d => d.model_version === "Final Forecast")
            .map(d => ({
              x: new Date(d.order_day).getTime(),
              y50: Number(d.p50),
              y90: Number(d.p90)
            }))
            .sort((a, b) => a.x - b.x);

          const otherForecastData = sortedData
            .filter(d => d.model_version !== "Final Forecast")
            .map(d => ({
              x: new Date(d.order_day).getTime(),
              y50: Number(d.p50),
              y90: Number(d.p90)
            }))
            .sort((a, b) => a.x - b.x);
    
            const lineData = sortedData
            .filter(d => d.model_version === "Final Forecast")
            .map(d => ({
              x: new Date(d.order_day).getTime(),
              y50: Number(d.p50),
              y90: Number(d.p90)
            }))
            .sort((a, b) => a.x - b.x);

        const allValues = [
          ...finalForecastData.map(d => d.y50),
          ...finalForecastData.map(d => d.y90),
          ...otherForecastData.map(d => d.y50),
          ...otherForecastData.map(d => d.y90),
          ...sortedData.map(d => Number(d.units) || 0)
        ];

        const allDates = [
          ...finalForecastData.map(d => d.x),
          ...otherForecastData.map(d => d.x)
        ];

          const yMax = Math.max(...allValues) * 1.2;
          const xMin = Math.min(...allDates);
          const xMax = Math.max(...allDates);

          return (
            <div key={site} style={{ marginBottom: '2rem' }} className="victory-chart">
              <VictoryChart
                width={2000}
                height={500}
                padding={{ top: 50, bottom: 50, left: 50, right: 50 }}
                scale={{ x: "time" }}
                domain={{ x: [xMin, xMax], y: [0, yMax] }}
                containerComponent={
                  <VictoryVoronoiContainer
                    labels={({ datum }) => {
                      if (!datum) return "";
                      const date = format(new Date(datum.x), 'MM/dd/yyyy');
                      if (datum.y50 !== undefined) {
                        return `Date: ${date}\nP50: ${datum.y50}`;
                      }
                      if (datum.y90 !== undefined) {
                        return `Date: ${date}\nP90: ${datum.y90}`;
                      }
                      if (datum.promo_or_markdown) {
                        return `${datum.promo_or_markdown}\nStart: ${format(new Date(datum.date_start), 'MM/dd/yyyy')}\nEnd: ${format(new Date(datum.date_end), 'MM/dd/yyyy')}`;
                      }
                      return `Date: ${date}\nValue: ${Number(datum.y).toFixed(2)}`; 
                    }}
                    labelComponent={
                      <VictoryTooltip
                        style={{ fontSize: 12 }}
                        flyoutStyle={{
                          stroke: "#757575",
                          fill: "white",
                          opacity: 0.9
                        }}
                      />
                    }
                  />
                }
              >
                {/* Holiday Lines */}
                <VictoryGroup>
                  {sortedData
                    .filter(d => d.is_holiday === 1)
                    .map((holiday, index) => (
                      <VictoryLine
                        key={`holiday-${index}`}
                        data={[
                          { 
                            x: holiday.holiday_date ? new Date(holiday.holiday_date).getTime() : 0,
                            y: 0 
                          },
                          { 
                            x: holiday.holiday_date ? new Date(holiday.holiday_date).getTime() : 0,
                            y: yMax 
                          }
                        ]}
                        style={{
                          data: {
                            stroke: "red",
                            strokeWidth: 2,
                            strokeDasharray: "4,4,1,4"
                          }
                        }}
                      />
                    ))}
                </VictoryGroup>

                {/* Promotional and Markdown Highlights */}
                <VictoryGroup>
                  {sortedData
                    .filter(d => d.promo_or_markdown === "Promo" || d.promo_or_markdown === "Markdown")
                    .map((period, index) => (
                      <VictoryBar
                        key={`${period.promo_or_markdown}-${index}`}
                        data={[{
                          x: (new Date(period.date_start).getTime() + new Date(period.date_end).getTime()) / 2,
                          y: yMax,
                          width: new Date(period.date_end).getTime() - new Date(period.date_start).getTime()
                        }]}
                        style={{
                          data: {
                            fill: period.promo_or_markdown === "Promo" ? "gray" : "purple",
                            opacity: 0.15
                          }
                        }}
                      />
                    ))}
                </VictoryGroup>

                
                {/* Final Forecast P50 Line */}
                <VictoryLine
                  name="final-p50-line"
                  data={finalForecastData.map(d => ({
                    x: d.x,
                    y: d.y50
                  }))}
                  interpolation="linear"
                  style={{
                    data: {
                      stroke: "firebrick",
                      strokeWidth: 2
                    }
                  }}
                />

                {/* Final Forecast P90 Line */}
                <VictoryLine
                  name="final-p90-line"
                  data={finalForecastData.map(d => ({
                    x: d.x,
                    y: d.y90
                  }))}
                  interpolation="linear"
                  style={{
                    data: {
                      stroke: "firebrick",
                      strokeWidth: 2,
                      strokeDasharray: "5,5",
                      strokeOpacity: 1
                    }
                  }}
                />

                {/* FAST Forecast P50 Line */}
                <VictoryLine
                  name="other-p50-line"
                  data={otherForecastData.map(d => ({
                    x: d.x,
                    y: d.y50
                  }))}
                  interpolation="linear"
                  style={{
                    data: {
                      stroke: "#4682B4", // steelblue
                      strokeWidth: 3,     // slightly thicker
                      strokeOpacity: 1
                    }
                  }}
                />

                {/* FAST Forecast P90 Line */}
                <VictoryLine
                  name="other-p90-line"
                  data={otherForecastData.map(d => ({
                    x: d.x,
                    y: d.y90
                  }))}
                  interpolation="linear"
                  style={{
                    data: {
                      stroke: "#4682B4", // steelblue
                      strokeWidth: 3,     // slightly thicker
                      strokeDasharray: "5,5",
                      strokeOpacity: 1
                    }
                  }}
                />

                {/* Debug Points */}
                <VictoryScatter
                  name="p90-points"
                  data={lineData.map(d => ({
                    x: d.x,
                    y: d.y90
                  }))}
                  size={4}
                  style={{
                    data: {
                      fill: "steelblue",
                      stroke: "white",
                      strokeWidth: 1
                    }
                  }}
                />

                {/* Actual Points */}
                <VictoryScatter
                  name="actual-points"
                  data={sortedData.map(d => ({
                    x: new Date(d.order_day).getTime(),
                    y: Number(d.units) || 0,
                    instock: d.instock_raw
                  }))}
                  size={4}
                  style={{
                    data: {
                      fill: ({ datum }) => `hsl(${(datum.instock || 0) * 120}, 70%, 50%)`,
                      stroke: "black",
                      strokeWidth: 0.5
                    }
                  }}
                />

                {/* Promotional Labels */}
                <VictoryLabel
                  data={sortedData
                    .filter(d => d.promo_or_markdown === "Promo" || d.promo_or_markdown === "Markdown")
                    .map(period => ({
                      x: new Date(period.date_start).getTime(),
                      y: yMax * 0.95,
                      label: period.promo_or_markdown
                    }))}
                  style={{
                    fontSize: 8,
                    fill: "black"
                  }}
                />

                <VictoryAxis
                  tickFormat={(t) => format(new Date(t), 'MM/dd')}
                  style={{
                    tickLabels: { fontSize: 8, padding: 5 },
                    grid: { stroke: "lightgray", strokeWidth: 0.5 }
                  }}
                />
                <VictoryAxis
                  dependentAxis
                  style={{
                    tickLabels: { fontSize: 8, padding: 5 },
                    grid: { stroke: "lightgray", strokeWidth: 0.5 }
                  }}
                />
              </VictoryChart>
            </div>
          );
        })}
        <Legend items={legendItems} />
      </div>
    </Box>
  );
};

export default ForecastChart;
