import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import Container from '@amzn/awsui-components-react/polaris/container';

interface LoadingComponentProps {
  loadingText?: string;
  size?: "normal" | "big" | "large";
  center?: boolean;
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({ 
  loadingText = "Loading...", 
  size = "large",
  center = true 
}) => {
  const content = (
    <SpaceBetween direction="vertical" size="s">
      <Box textAlign={center ? "center" : "left"}>
        <Spinner size={size} />
        <Box variant="p">
          {loadingText}
        </Box>
      </Box>
    </SpaceBetween>
  );

  return center ? (
    <Container>
      <Box
        margin={{ vertical: "xxl" }}
        padding={{ vertical: "xxl" }}
        textAlign="center"
      >
        {content}
      </Box>
    </Container>
  ) : content;
};

export default LoadingComponent;
