import React, { useEffect, useMemo, useState } from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Row from "@amzn/meridian/row";
import Button from '@amzn/awsui-components-react/polaris/button';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Tabs from '@amzn/awsui-components-react/polaris/tabs';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import UnifiedTable from './UnifiedTable';
import ForecastChart from './ForecastChart';
import InputForm from './InputForm';
import { useUnifiedData } from '../../hooks/useUnifiedData';
import LoadingComponent from '../Loading';
// import CombinedDataMock from '../../mock/combinedDataMock';
const Deepdive: React.FC = () => {
  // State management
  const [chartParams, setChartParams] = useState({
    warehouse: 'MAB2',
    asin: 'B09LNW7L55',
    business_id: 'AFS',
    start_date: '2024-11-07',
    end_date: '2024-12-15',
    fcd: '2024-11-20',
    models_versions: 'numpyro-promo-lifts-v1'
  });

  const [activeTab, setActiveTab] = useState('both');
  const [isDataGenerated, setIsDataGenerated] = useState(false);

  // Data fetching
  const { 
    data: unifiedData, 
    isLoading, 
    error 
  } = useUnifiedData(
    chartParams.warehouse,
    chartParams.asin,
    chartParams.business_id,
    chartParams.start_date,
    chartParams.end_date,
    chartParams.fcd,
    chartParams.models_versions
  );

  // Mock Data
  // const {
  //   data: unifiedData,
  //   isLoading,
  //   error
  // } = useMemo(() => {
  //   // Simulate loading state if needed
  //   const loading = false;
    
  //   // Filter mock data based on chartParams
  //   const filteredData = CombinedDataMock.filter(item => {
  //     const itemDate = new Date(item.order_day);
  //     const startDate = new Date(chartParams.start_date);
  //     const endDate = new Date(chartParams.end_date);
      
  //     return (
  //       item.business_id === chartParams.business_id &&
  //       item.asin === chartParams.asin &&
  //       item.site_id === chartParams.warehouse &&
  //       itemDate >= startDate &&
  //       itemDate <= endDate
  //     );
  //   });
  
  //   return {
  //     data: filteredData,
  //     isLoading: loading,
  //     error: null
  //   };
  // }, [
  //   chartParams.warehouse,
  //   chartParams.asin,
  //   chartParams.business_id,
  //   chartParams.start_date,
  //   chartParams.end_date
  // ]);

  

  // Handlers
  const handleParamsChange = (newParams: typeof chartParams) => {
    setChartParams(prevParams => ({
      ...prevParams,
      ...newParams
    }));    
    setIsDataGenerated(false);

  };

  const handleGenerateChart = () => {
    setIsDataGenerated(true);
  };

  const areParamsValid = () => {
    return Object.entries(chartParams).every(([key, value]) => {
      if (typeof value !== 'string') return false;
      return value.trim().length > 0;
    });
  };

  // Render loading state
  if (isLoading && isDataGenerated) {
    return (
      <LoadingComponent loadingText="Generating visualization..." />
    );
  }

  // Main render
  return (
    <SpaceBetween size="l">
      {/* Error handling */}
      {error && (
        <Alert
          type="error"
          header="Error loading data"
          dismissible
        >
          {error}
        </Alert>
      )}

      {/* Input Form - Always visible */}
      <Box>
        <SpaceBetween size="l">
          <InputForm
            initialValues={chartParams}
            onParamsChange={handleParamsChange}
          />
          
          <Row alignmentHorizontal="center">
            <Button 
              variant="primary" 
              onClick={handleGenerateChart}
              disabled={!areParamsValid()}
            >
              Generate Data
            </Button>
          </Row>
        </SpaceBetween>
      </Box>

      {/* Data Visualization Section */}
      {isDataGenerated && unifiedData && (
        <Box>
          <SpaceBetween size="l">
            {/* View Toggle */}
            <Tabs
              activeTabId={activeTab}
              onChange={({ detail }) => setActiveTab(detail.activeTabId)}
              tabs={[
                {
                  id: 'both',
                  label: 'Both Views',
                  content: (
                    <SpaceBetween size="l">
                      <ForecastChart
                        data={unifiedData}
                        asin={chartParams.asin}
                        title='Unified Chart'
                      />
                      <UnifiedTable
                        data={unifiedData}
                        isLoading={false}
                      />
                    </SpaceBetween>
                  )
                },
                {
                  id: 'chart',
                  label: 'Chart View',
                  content: (
                    <ForecastChart
                      data={unifiedData}
                      asin={chartParams.asin}
                      title='Unified Chart'
                    />
                  )
                },
                {
                  id: 'table',
                  label: 'Table View',
                  content: (
                    <UnifiedTable
                      data={unifiedData}
                      isLoading={false}
                    />
                  )
                }
              ]}
            />
          </SpaceBetween>
        </Box>
      )}

      {/* No Data State */}
      {isDataGenerated && !unifiedData && !isLoading && !error && (
        <Box textAlign="center" padding={{ vertical: "xxl" }}>
          <Alert
            type="info"
            header="No data available"
          >
            No data is available for the selected parameters. Please try different parameters.
          </Alert>
        </Box>
      )}
    </SpaceBetween>
  );
};

export default Deepdive;
