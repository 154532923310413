import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';

export interface LegendItem {
  name: string;
  color: string;
  type: 'line' | 'scatter' | 'rect';
  isDashed?: boolean;
  dashPattern?: string;
}

const Legend: React.FC<{ items: LegendItem[] }> = ({ items }) => {
  const renderLegendIcon = (item: LegendItem) => {
    if (item.type === 'rect') {
      return (
        <div
          style={{
            width: '20px',
            height: '20px',
            backgroundColor: item.color,
            marginRight: '5px'
          }}
        />
      );
    }

    if (item.type === 'line') {
      return (
        <div
          style={{
            width: '20px',
            height: '2px',
            marginRight: '5px',
            background: item.isDashed
              ? `repeating-linear-gradient(
                  to right,
                  ${item.color} 0px,
                  ${item.color} 4px,
                  transparent 4px,
                  transparent 8px
                )`
              : item.color,
            ...(item.dashPattern && {
              background: `repeating-linear-gradient(
                to right,
                ${item.color} 0px,
                ${item.color} 4px,
                transparent 4px,
                transparent ${item.dashPattern.split(',')[1]}px
              )`
            })
          }}
        />
      );
    }

    // Scatter point
    return (
      <div
        style={{
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          backgroundColor: item.color,
          marginRight: '5px'
        }}
      />
    );
  };

  return (
    <Box margin={{ top: 'l' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
        {items.map((item, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              minWidth: 'fit-content'
            }}
          >
            {renderLegendIcon(item)}
            <span style={{ fontSize: '14px' }}>{item.name}</span>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default Legend;
