import "ka-table/style.scss";
import React from 'react';
import '../styles.scss';
import DatePicker from "@amzn/meridian/date-picker"

const width = 300;
interface WorkflowDateProps {
  onChangeEvent: (e) => any;
  setDate: string;
  label: string;
  error?: string;
}

const WorkflowDate: React.FC<WorkflowDateProps> = (props: WorkflowDateProps) => {
  const { onChangeEvent, setDate, label, error } = props;
  
  return (
    <div>
      <DatePicker 
        value={setDate} 
        onChange={onChangeEvent} 
        width={width} 
        label={label}
        error={!!error}
      />
      {error && <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>{error}</div>}
    </div>
  );
}

export default WorkflowDate;
