import { useState, useEffect } from 'react';
import { CombinedData, CombinedDataParams } from '../types/combined';
import { CombinedService } from '../services/combinedService';

export const useCombined = (params: CombinedDataParams, shouldFetch: boolean = false) => {
  const [data, setData] = useState<CombinedData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let dataAchieved: boolean;

    const fetchWithRetry = async (retries = 3, delay = 1000) => {
      let lastError: Error | null = null;

      for (let attempt = 1; attempt <= retries; attempt++) {
        try {
          const response = await CombinedService.getCombined(params);
          
          if (response !== null && response !== undefined) {
            console.log('Combined service returned:', response);
            setData(response);
            setError(null);
            dataAchieved = true;
            break;
          }
          
          // If response is falsy, store the error but don't throw yet
          lastError = new Error('No combined data received');
          
        } catch (err) {
          lastError = err as Error;
        }

        if (attempt < retries) {
          // Calculate and wait backoff delay if we're going to retry
          const backoffDelay = delay * Math.pow(2, attempt - 1);
          await new Promise(resolve => setTimeout(resolve, backoffDelay));
        }
      }
      if(!dataAchieved) {
        // If we get here, all retries failed
        setError('Failed to fetch actuals data after multiple attempts');
      }
    };

    const fetchData = async () => {
      if (!shouldFetch) return;
      setIsLoading(true);
      setError(null);
      setData([]);
      
      try {
        await fetchWithRetry();
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    params.asin, 
    params.warehouse, 
    params.start_date, 
    params.end_date,
    params.fcd,
    params.models_versions,
    params.business_id,
    shouldFetch
  ]);

  return { data, isLoading, error };
};
