// src/hooks/usePromo.ts
import { useState, useEffect } from 'react';
import { PromoData, PromoParams } from '../types/promo';
import { PromoService } from '../services/promoService';

export const usePromo = (params: PromoParams, shouldFetch: boolean = false) => {
  const [data, setData] = useState<PromoData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {      
    let dataAchieved: boolean;

    const fetchWithRetry = async (retries = 3, delay = 1000) => {
      let lastError: Error | null = null;
      for (let attempt = 1; attempt <= retries; attempt++) {
        try {
          const response = await PromoService.getPromos(params);
          
          if (response !== null && response !== undefined) {
            console.log('Promo data.......:', response);
            setError(null);
            setData(Array.isArray(response) ? response : []);
            dataAchieved = true;
            break;
          }
          setData([]);
          dataAchieved = true;
          break;
        } catch (err) {
          lastError = err as Error;
          console.error(`Promo fetch error (attempt ${attempt}/${retries}):`, err);
          
          if (attempt < retries) {
            const backoffDelay = delay * Math.pow(2, attempt - 1);
            console.log(`Retrying in ${backoffDelay}ms...`);
            await new Promise(resolve => setTimeout(resolve, backoffDelay));
          }
        }
      }

      if(!dataAchieved) {
        console.log('No promo data available, using empty array');
        setData([]);
        setError(null);
      }
      return false;
    };

    const fetchData = async () => {
      if (!shouldFetch) return;
      
      setIsLoading(true);
      setError(null);
      
      try {
        await fetchWithRetry();
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    params.asin, 
    params.warehouse, 
    params.start_date,
    params.end_date, 
    shouldFetch
  ]);

  return { data, isLoading, error };
};
